import React, { ReactNode, Fragment } from 'react';
import { RouteComponentProps } from '@reach/router';

import HSFStoriesContextProvider from './hsfstories-context';

type HSFStoriesProps = RouteComponentProps & {
  children?: ReactNode;
};
const HSFStory = (props: HSFStoriesProps) => (
  <Fragment>
    <HSFStoriesContextProvider>{props.children}</HSFStoriesContextProvider>
  </Fragment>
);

export default HSFStory;
