import styled from '@emotion/styled';
import { Form, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useQuery } from 'react-query';
import produce from 'immer';
import { debounce } from 'lodash';

import { formSchema, FormSchemaKeys, ISectionItem } from './schema';
import { useHSFStoriesQuery } from './hsfstories-context';

import { Button, Tooltip } from '../../../components';
import { Select, FormElementChangeEvent } from '../../../components/forms';
import { HSFStoriesCMSType, University as UniversityModel } from '../../../types/models/hsfstory';
import { getUniversities, getGrammarSchoolOptions } from '../../../apis/public/hsfstories';
import { OptionProps } from '../../../components/forms/select';
import FilterIcon from '../../../components/common/icons/FilterIcon';

const Container = styled.div`
  max-height: 77vh;
  overflow-y: scroll;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

const FilterItemRow = styled.div`
  display: flex;
  align-items: center;
`;

const mapToOptions = ({ name }: Partial<University>): OptionProps => {
  return {
    label: name,
    value: name,
  };
};

const mapGrammarSchoolsToOptions = ({ option_value: label, id: value }: any): OptionProps => ({
  label,
  value,
});

type University = Pick<UniversityModel, 'id' | 'name' | 'name'>;
type UniversityMap = {
  [key: string]: University;
};

type HSFStoriesModalProps = {
  collegeMajors: OptionProps[];
};

export function HSFStoriesModal({ collegeMajors }: HSFStoriesModalProps) {
  const { allDirectusHighSchoolOption, allDirectusHometownOption } = useStaticQuery(graphql`
    query {
      allDirectusHighSchoolOption {
        nodes {
          directusId
          name
        }
      }
      allDirectusHometownOption {
        nodes {
          directusId
          name
        }
      }
    }
  `);

  const [state, setState] = useState<Partial<HSFStoriesCMSType>>({});
  const [visible, toggleVisible] = useState(false);
  const [filterUniversitiesText, setFilterUniversitiesText] = useState<string>('');
  const [filterPage, setFilterPage] = useState<{ [key: string]: number }>({});
  const [universities, setUniversities] = useState<University[]>([]);
  const [grammarSchoolOptions, setGrammarSchoolOptions] = useState<any>([]);

  const getOptionsList = (nodes: { directusId: number; name: string }[]) =>
    nodes.map(({ directusId, name }) => ({ label: name, value: directusId }));

  const optionsByKey = useMemo(
    (): Record<string, any> => ({
      [FormSchemaKeys.HIGH_SCHOOL]: getOptionsList(allDirectusHighSchoolOption.nodes),
      [FormSchemaKeys.HOMETOWN]: getOptionsList(allDirectusHometownOption.nodes),
    }),
    [allDirectusHighSchoolOption, allDirectusHometownOption]
  );

  const { data: universityData, isLoading: isLoadingUniversities } = useQuery(
    [getUniversities.QUERY_KEY, { searchTerm: filterUniversitiesText, page: filterPage[filterUniversitiesText] || 0 }],
    ({ queryKey }: any) => getUniversities(queryKey[1]),
    {
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (data) => {
        setUniversities((previous) => {
          const previousUMap = previous.reduce(
            (result: UniversityMap, university) =>
              produce(result, (draft) => {
                draft[university.id] = university;
              }),
            {}
          );

          const currentDataUMap = data.reduce(
            (result: UniversityMap, university) =>
              produce(result, (draft) => {
                draft[university.id] = university;
              }),
            {}
          );

          return Object.values(
            produce(previousUMap, (draft) => {
              Object.assign(draft, currentDataUMap);
            })
          ).sort((a: University, b: University) => {
            if (!a || !b) {
              return 0;
            }
            if (a && b && a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
      },
    }
  );
  const { data: grammarData, isLoading: isLoadingGrammarSchools } = useQuery(
    [getGrammarSchoolOptions.QUERY_KEY, {}],
    getGrammarSchoolOptions,
    {
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (data) => {
        setGrammarSchoolOptions(data);
      },
    }
  );

  const _nextFilterPage = useCallback(() => {
    setFilterPage((previous) =>
      produce(previous, (draft) => {
        if (filterUniversitiesText in draft) {
          draft[filterUniversitiesText] += 1;
        } else {
          draft[filterUniversitiesText] = 1;
        }
      })
    );
  }, [filterUniversitiesText]);

  const { setFilterQuery, queryOptions } = useHSFStoriesQuery();
  // Grab meta data of selected uni's
  const { selectedUniversities = [] } = queryOptions;
  const universityOptions = useMemo(() => {
    const selectedIds = selectedUniversities.map((university) => university.name);
    if (universityData) {
      // Filter universities that are already selected and place them first in the list
      const filteredUniversityData = universityData.filter((university) => !selectedIds.includes(university.name));
      return [...selectedUniversities, ...filteredUniversityData].map(mapToOptions);
    }
    // Filter universities that are already selected and place them first in the list
    const filteredUniversities = universities.filter((university) => !selectedIds.includes(university.name));
    return universities ? [...selectedUniversities, ...filteredUniversities].map(mapToOptions) : [];
  }, [universityData, universities, selectedUniversities]);

  const grammarSchools = useMemo(() => {
    if (grammarData) {
      return grammarData.map(mapGrammarSchoolsToOptions);
    }
    return grammarSchoolOptions ? grammarSchoolOptions.map(mapGrammarSchoolsToOptions) : [];
  }, [grammarData, grammarSchoolOptions]);

  const handleOpen = () => {
    setState((current: any) => ({ ...current, ...queryOptions }));
    toggleVisible(true);
  };

  const handleClose = () => {
    toggleVisible(false);
  };

  const clearFilter = () => {
    setState({});
  };

  const saveFilter = () => {
    setFilterQuery(state);
    handleClose();
  };

  const getFieldChangeHandler =
    (filterKey: keyof HSFStoriesCMSType) =>
    ({ value }: FormElementChangeEvent) => {
      setState((current) => ({
        ...current,
        [filterKey]: value,
      }));
    };

  /**
   * Handler that includes meta data for the list of selected uni's
   */
  const getUniversityChangeHandler =
    (filterKey: keyof HSFStoriesCMSType) =>
    ({ value }: FormElementChangeEvent) => {
      setState((current) => ({
        ...current,
        [filterKey]: value,
        ['selectedUniversities']: value.map((selected: string) =>
          universities.find((uni) => uni.name === selected)
        ),
      }));
    };

  const onScroll = useCallback(
    (e) => {
      const target = e.target;
      if (
        !isLoadingUniversities &&
        !isLoadingGrammarSchools &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        _nextFilterPage();
      }
    },
    [isLoadingUniversities, isLoadingGrammarSchools, _nextFilterPage]
  );

  const onSearch = useCallback(
    debounce((value: string) => {
      if (value.length > 3 || value.length === 0) {
        setFilterUniversitiesText(value);
      }
    }, 300),
    []
  );

  const renderSelectItem = ({ label, name, options, tooltip }: ISectionItem) => {
    if (label === 'University') {
      return (
        <Form.Item label={label} key={name}>
          <FilterItemRow>
            <Select
              style={{ maxWidth: 700, flex: 1 }}
              mode="multiple"
              showSearch
              placeholder={MULTIPLE_SELECT_PLACEHOLDER}
              value={state[name]}
              name={name}
              options={universityOptions}
              onChange={getUniversityChangeHandler(name)}
              onSearch={onSearch}
              onPopupScroll={onScroll}
              filterOption={(input, option) =>
                !!option?.props?.children &&
                typeof option.props.children === 'string' &&
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            {tooltip && <Tooltip title={tooltip} />}
          </FilterItemRow>
        </Form.Item>
      );
    }
    if (label === 'Grammar School') {
      return (
        <Form.Item label={label} key={name}>
          <FilterItemRow>
            <Select
              style={{ maxWidth: 700, flex: 1 }}
              mode="multiple"
              showSearch
              placeholder={MULTIPLE_SELECT_PLACEHOLDER}
              value={state[name]}
              name={name}
              options={grammarSchools}
              onChange={getFieldChangeHandler(name)}
              onSearch={onSearch}
              onPopupScroll={onScroll}
            />
            {tooltip && <Tooltip title={tooltip} />}
          </FilterItemRow>
        </Form.Item>
      );
    }

    if (label === 'Graduate Degree' || label === 'Undergraduate Degree') {
      return (
        <Form.Item label={label} key={name}>
          <FilterItemRow>
            <Select
              style={{ maxWidth: 700, flex: 1 }}
              mode="multiple"
              showSearch
              placeholder={MULTIPLE_SELECT_PLACEHOLDER}
              value={state[name]}
              name={name}
              options={collegeMajors}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.props?.children !== null &&
                typeof option.props.children === 'string' &&
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={getFieldChangeHandler(name)}
            />
            {tooltip && <Tooltip title={tooltip} />}
          </FilterItemRow>
        </Form.Item>
      );
    }

    return (
      <Form.Item label={label} key={name}>
        <FilterItemRow>
          <Select
            mode="multiple"
            showSearch
            style={{ maxWidth: 700, flex: 1 }}
            placeholder={MULTIPLE_SELECT_PLACEHOLDER}
            value={state[name]}
            name={name}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option &&
              option.props !== null &&
              option.props.children !== null &&
              typeof option.props.children === 'string' &&
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={optionsByKey[name] || options}
            onChange={getFieldChangeHandler(name)}
          />
          {tooltip && <Tooltip title={tooltip} />}
        </FilterItemRow>
      </Form.Item>
    );
  };

  return (
    <>
      <FilterIcon />
      <Button key="filter" type="link" onClick={handleOpen}>
        More filters
      </Button>
      <Modal
        style={{ top: 0, bottom: 0, padding: 0 }}
        width={1200}
        visible={visible}
        title="More Filters"
        closable={true}
        onCancel={handleClose}
        footer={[
          <StyledDiv key="1">
            <Button key="clear" type="link" onClick={clearFilter}>
              Clear Filter
            </Button>
            <div>
              <Button key="cancel" type="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button key="apply" type="primary-blue" onClick={saveFilter}>
                Apply Filters
              </Button>
            </div>
          </StyledDiv>,
        ]}
      >
        <Container>{formSchema.map(renderSelectItem)}</Container>
      </Modal>
    </>
  );
}
