import axios from 'axios';
import { get } from 'lodash';

import { stringifyHSFStoriesQueryFilter } from './hsfstories-utils';

import {
  HSFStoriesCMSType,
  HSFStoriesType,
  HSFStory,
  MajorClassification,
  University,
  UniversityResponse,
} from '../../types/models/hsfstory';
import { CMS_URL } from '../../constants';
import { createError } from '../error';
import responseParser from '../response-parser';

export interface IHSFStoryQuery extends Partial<HSFStoriesCMSType> {
  offset?: number;
  searchText?: string;
  selectedUniversities?: Partial<University>[]; // Used to store Meta data about the selected Uni's
}

const setHttp = (link: string) => {
  if (link.search(/^http[s]?:\/\//) == -1) {
    link = 'http://' + link;
  }
  return link;
};

const parseUniversity = (record: UniversityResponse): University => ({
  id: record.id,
  universityId: record.unit_id,
  name: record.school_name,
  schoolType: record.school_type,
  schoolSize: record.school_size_group,
  street: record.street,
  city: record.city,
  state: record.state,
  zipcode: record.zipcode?.zipcode,
  phone: record.phone,
  url: record.web_institution ? setHttp(record.web_institution?.toLocaleLowerCase()) : '',
  image: record.image,
  gender: record.gender_type,
  religiousAffiliation: record.religious_affiliation,
  institutionControl: record.institution_control,
  admissionSelectivity: record.admission_selectivity,
  satMathMedian: record.sat_math_median,
  satWriteMedian: record.sat_write_median,
  actComprehensive: record.act_cm_mid,
  averageGraduationRate: record.average_6yr_graduation_rate,
  medianGraduateIncome: record.grad_median_income_10yr_post_enrollment,
  schoolSettings: record?.zipcode?.locales?.map((item) => ({
    title: item?.locale.locale,
    group: item?.locale.locale_group,
  })),
  majors: record?.majors?.map((item) => ({ majorId: item.major_id })),
  ethnicities: record?.ethnicities?.map((item) => ({
    ethnicityId: item.ethnicity_id,
    enrollmentRate: item.enrollment_rate,
  })),
  averageHouseholdIncome: record?.avg_net_prices?.map((item) => ({
    familyIncomeRange: item.family_income_range,
    averageTotalCostOfAttendance: item.average_total_cost_of_attendance,
  })),
  averageNetCost: record.average_total_cost_of_attendance,
  tuitionInState: record.tuition_fee_instate,
  tuitionOutState: record.tuition_fee_outstate,
});

export async function getUniversities(queryParams: { searchTerm: string; page: number }): Promise<University[]> {
  const DEFAULT_PAGE_SIZE = 20;
  const searchTerm = get(queryParams, 'searchTerm', '');
  const page = get(queryParams, 'page', '');
  // console.log(`Search: ${searchTerm}   --  Page: ${page}`);
  try {
    const url = `${CMS_URL}/items/universities`;
    const params: { [key: string]: any } = {
      fields: 'id,unit_id,school_name',
      sort: 'school_name',
      offset: Number(page) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
    };
    if (searchTerm) {
      params['filter[school_name][like]'] = `${searchTerm}`;
    }
    const response = await axios.get(url, { params });

    return responseParser(response).map(parseUniversity);
  } catch (error) {
    throw createError(error);
  }
}
getUniversities.QUERY_KEY = 'public:getUniversities';

export async function getGrammarSchoolOptions(): Promise<any[]> {
  try {
    const url = `${CMS_URL}/items/grammar_school_options`;
    const params: { [key: string]: any } = {
      fields: 'id,option_value',
    };

    const response = await axios.get(url, { params });

    return responseParser(response);
  } catch (error) {
    throw createError(error);
  }
}
getGrammarSchoolOptions.QUERY_KEY = 'public:getGrammarSchoolOptions';

function parseCMSHSFStory(data: HSFStoriesCMSType): HSFStory {
  return {
    id: data.id,
    name: data.name,
    image: data.image,
    userType: data.user_type,
    gender: data.gender,
    roleCategory: data.role_category,
    hometown: data.hometown,
    university: data.university,
    highSchool: data.high_school,
    undergradDegree: data.undergrad_degree,
    gradDegree: data.grad_degree,
    story: data.story,
    ctaLabel: data.cta_label,
    slug: data.slug,
    status: data.status,
    url: data.url,
    featured: data.featured.toString(),
    createdOn: new Date(data.created_on).getTime(),
    positionTitle: data.position_title,
  };
}

export async function getHSFStoriesToPages(filterQuery: IHSFStoryQuery): Promise<HSFStoriesType> {
  const meta = `filter_count,total_count,result_count`;
  const sort = 'name';

  const url = `${CMS_URL}/items/hsfstories?${stringifyHSFStoriesQueryFilter(
    filterQuery
  )}&sort=${sort}&meta=${meta}&status=published`;

  try {
    const response = await axios.get(url);
    const hsfStories: HSFStoriesCMSType[] = responseParser(response);
    const result = {
      hsfStories: hsfStories.map(parseCMSHSFStory),
      totalCount: response.data.meta.filter_count,
    };
    return result;
  } catch (err) {
    throw createError(err);
  }
}
getHSFStoriesToPages.QUERY_KEY = 'getHSFStoriesToPages';

export async function getHSFStoriesFilters({ searchTerm }: { searchTerm: string }): Promise<Array<Partial<HSFStory>>> {
  const params: { [key: string]: any } = {
    fields: 'id,name',
    sort: 'name',
    limit: 5,
  };
  if (searchTerm) {
    params['filter[name][like]'] = `${searchTerm}`;
  }
  try {
    const url = `${CMS_URL}/items/hsfstories`;
    const response = await axios.get(url, { params });
    const scholarships: HSFStoriesCMSType[] = responseParser(response);
    return scholarships.map(parseCMSHSFStory);
  } catch (err) {
    throw createError(err);
  }
}

getHSFStoriesFilters.QUERY_KEY = 'getHSFStoriesFilters';

export async function getHSFStoriesDetail(id: string): Promise<HSFStory> {
  try {
    const url = `${CMS_URL}/items/hsfstories/${id}`;
    const response = await axios.get(url);
    const scholarship = responseParser(response);
    return parseCMSHSFStory(scholarship);
  } catch (error) {
    throw createError(error);
  }
}
getHSFStoriesDetail.QUERY_KEY = 'getHSFStoriesDetail';

const parseMajorClassification = (record: { major_id: string; major_name: string }): MajorClassification => ({
  majorId: record.major_id,
  majorName: record.major_name,
});

export async function getMajorsClassifications(): Promise<MajorClassification[]> {
  const url = `${CMS_URL}/items/major_classification`;
  try {
    const response = await axios.get(url, { params: { limit: -1, sort: 'major_name' } });
    return responseParser(response).map(parseMajorClassification);
  } catch (error) {
    throw createError(error);
  }
}
getMajorsClassifications.QUERY_KEY = 'public:getMajorsClassifications';
