import React from 'react';

import HSFStoriesList from '../containers/myhsf/hsfstories/hsfstories-list';
import HSFStory from '../containers/myhsf/hsfstories';
import HSFStoriesDetail from '../containers/myhsf/hsfstories/hsfstories-detail';

export default function HsfStories() {
  return (
    <HSFStory>
      <HSFStoriesList />
      <HSFStoriesDetail />
    </HSFStory>
  );
}
