import { IHSFStoryQuery } from './hsfstories';

import { DEFAULT_PAGE_SIZE } from '../../components/table/pagination';

enum QueryFilters {
  in = 'in',
  range = 'range',
  eq = 'eq',
  like = 'like',
}

enum QueryOperators {
  in = 'in',
  lt = 'lt',
  between = 'between',
  gt = 'gt',
  eq = 'eq',
  like = 'like',
}

const operatorsMap: Record<QueryFilters, Array<keyof IHSFStoryQuery>> = {
  [QueryFilters.in]: ['id', 'role_category', 'major'],
  [QueryFilters.range]: [],
  [QueryFilters.eq]: [],
  [QueryFilters.like]: [],
};

function renderSeparator(isLast: boolean, separator = ',') {
  return isLast ? '' : separator;
}

const getFilterValue = (value: string[] | string | number) => {
  if (Array.isArray(value)) {
    return value.reduce((result, item, index) => `${result}${item}${renderSeparator(value.length === index + 1)}`, '');
  }
  return value;
};

function compileKeyForRangeQuery(key: keyof IHSFStoryQuery, value: string[]) {
  const getValueForBetweenOperator = (valueItem: string, operator: string) => {
    if (operator === 'between') {
      return valueItem.split('-').join(',');
    }
    return valueItem.substring(1);
  };

  return value.reduce((result, valueItem, index) => {
    const isLtOperator = valueItem.startsWith('<');
    const isGtOperator = valueItem.startsWith('>');
    const operator = isLtOperator ? QueryOperators.lt : isGtOperator ? QueryOperators.gt : QueryOperators.between;
    const stringValue = getValueForBetweenOperator(valueItem, operator);
    const isLast = index + 1 === value.length;
    const orOperator = `&filter[${key}][${index + 1}][logical]=or`;
    return `${result}&filter[${key}][${index}][${operator}]=${stringValue}${renderSeparator(isLast, orOperator)}`;
  }, ``);
}

function compileKeyForEQQuery(key: keyof IHSFStoryQuery, value: string[]) {
  const operator = QueryOperators.eq;

  return value.reduce((result, item, index) => {
    const isLast = index + 1 === value.length;
    const orOperator = `&filter[${key}][${index + 1}][logical]=or`;
    return `${result}&filter[${key}][${index}][${operator}]=${item}${renderSeparator(isLast, orOperator)}`;
  }, '');
}

function compileKeyForLikeQuery(key: keyof IHSFStoryQuery, value: string[]) {
  const operator = QueryOperators.like;

  return value.reduce((result, item, index) => {
    const isLast = index + 1 === value.length;
    const orOperator = `&filter[${key}][${index + 1}][logical]=or`;
    return `${result}&filter[${key}][${index}][${operator}]=${item}${renderSeparator(isLast, orOperator)}`;
  }, '');
}

function compileKeyForINQuery(key: keyof IHSFStoryQuery, value: string[]) {
  const operator = QueryOperators.in;
  return `&filter[${key}][${operator}]=${getFilterValue(value)}`;
}

function getFilterQueryField(key: keyof IHSFStoryQuery, value: string[] | string | number | boolean | undefined) {
  const isEmptyArray = Array.isArray(value) && value.length === 0;

  // Filter out `selectedUniversities` since it is used as meta data for the filters
  if ((!value && value !== 0) || value === '' || isEmptyArray || key === 'selectedUniversities') {
    return '';
  }

  if (key === 'offset') {
    return `&offset=${value}`;
  }
  if (key === 'searchText') {
    return `&filter[name][like]=${value}`;
  }

  // handle key for range operators
  if (operatorsMap.range.includes(key)) {
    return compileKeyForRangeQuery(key, value as string[]);
  }

  // handle key for like operators
  if (operatorsMap.like.includes(key)) {
    return compileKeyForLikeQuery(key, value as string[]);
  }

  // handle key for eq operator
  if (operatorsMap.eq.includes(key)) {
    return compileKeyForEQQuery(key, value as string[]);
  }

  // handle key for in operator
  if (operatorsMap.in.includes(key)) {
    return compileKeyForINQuery(key, value as string[]);
  }

  return `&filter[${key}]=${value}`;
}

export function stringifyHSFStoriesQueryFilter(filters: IHSFStoryQuery) {
  return Object.keys(filters).reduce<string>((result: string, key: string) => {
    // Ignore signal key because it is an AbortSignal
    if (key === 'signal') {
      return result;
    }

    const value = filters[key as keyof IHSFStoryQuery];
    return `${result}${getFilterQueryField(key as keyof IHSFStoryQuery, value as any)}`;
  }, `&limit=${DEFAULT_PAGE_SIZE}`);
}
