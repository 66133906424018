import { OptionProps } from '../../../components/forms/select';
import { HSFStoriesCMSType } from '../../../types/models/hsfstory';
import { programShortName } from '../../../siteContent';

export interface ISectionItem {
  label: string;
  name: keyof HSFStoriesCMSType;
  options?: OptionProps[];
  tooltip?: string;
}

export const ScholarYearCounter = (startDate: number, endDate: number) => {
  const ScholarYear: { value: number; label: number }[] = [];

  for (let i = startDate; i <= endDate; i++) {
    ScholarYear.push({ value: i, label: i });
  }
  return ScholarYear;
};

const Hometown = [{ label: 'All', value: 'All' }];
const HighSchool = [{ label: 'All', value: 'All' }];
const University = [{ label: 'All', value: 'All' }];

const UndergraduateLevel = [
  { label: 'All', value: 'All' },
  { label: 'High School Senior', value: 'High School Senior' },
  { label: 'Undergraduate - all', value: 'Undergraduate-All' },
  { label: 'Freshman', value: 'Freshman' },
  { label: 'Sophmore', value: 'Sophmore' },
  { label: 'Junior', value: 'Junior' },
  { label: 'Senior', value: 'Senior' },
];

const GraduateLevel = [
  { label: 'All', value: 'All' },
  { label: 'Medical School', value: 'Medical School' },
  { label: 'Law School', value: 'Law School' },
  { label: 'Business School', value: 'Business School' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Computer Science', value: 'Computer Science' },
  { label: 'Other', value: 'Other' },
];

const Sex = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

export const RoleAndCategory = [
  { value: 'HSF Alumni', label: `${programShortName} Alumni` },
  { value: 'HSF Corporate Partner', label: `${programShortName} Corporate Partner` },
  { value: 'HSF Donors', label: `${programShortName} Donors` },
  { value: 'HSF Leaders', label: `${programShortName} Leaders` },
  { value: 'HSF Parents', label: `${programShortName} Parents` },
  { value: 'HSF Partners', label: `${programShortName} Partners` },
  { value: 'HSF Scholars', label: `${programShortName} Scholars` },
  { value: 'HSF Staff', label: `${programShortName} Staff` },
];

export const Industry = [
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Airlines', label: 'Airlines' },
  { value: 'Apparel', label: 'Apparel' },
  { value: 'Automotive Retailing, Services', label: 'Automotive Retailing, Services' },
  { value: 'Beverages', label: 'Beverages' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Commercial Banks', label: 'Commercial Banks' },
  { value: 'Computer Software', label: 'Computer Software' },
  { value: 'Computers, Office Equipment', label: 'Computers, Office Equipment' },
  { value: 'Construction and Farm Machinery', label: 'Construction and Farm Machinery' },
  { value: 'Direct Service', label: 'Direct Service' },
  { value: 'Diversified Outsourcing Services', label: 'Diversified Outsourcing Services' },
  { value: 'Education', label: 'Education' },
  { value: 'Electronics, Electrical Equip', label: 'Electronics, Electrical Equip.' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Equipment Leasing', label: 'Equipment Leasing' },
  { value: 'Financial Data Services', label: 'Financial Data Services' },
  { value: 'Food Production', label: 'Food Production' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Motor Vehicles and Parts', label: 'Motor Vehicles and Parts' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Securities', label: 'Securities' },
  { value: 'Semiconductors and Other Electronic Components', label: 'Semiconductors and Other Electronic Components' },
  { value: 'Shipping', label: 'Shipping' },
  { value: 'Specialty Retailers', label: 'Specialty Retailers' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Tobacco', label: 'Tobacco' },
  { value: 'Transportation Equipment', label: 'Transportation Equipment' },
];

export const Heritage = [
  { value: 'Argentine', label: 'Argentine' },
  { value: 'Belizean', label: 'Belizean' },
  { value: 'Bolivian', label: 'Bolivian' },
  { value: 'Brazilian', label: 'Brazilian' },
  { value: 'Chilean', label: 'Chilean' },
  { value: 'Colombian', label: 'Colombian' },
  { value: 'Costa Rican', label: 'Costa Rican' },
  { value: 'Cuban', label: 'Cuban' },
  { value: 'Dominican', label: 'Dominican' },
  { value: 'Ecuadorian', label: 'Ecuadorian' },
  { value: 'Guatemalan', label: 'Guatemalan' },
  { value: 'Honduran', label: 'Honduran' },
  { value: 'Mexican', label: 'Mexican' },
  { value: 'Nicaraguan', label: 'Nicaraguan' },
  { value: 'Panamanian', label: 'Panamanian' },
  { value: 'Paraguayan', label: 'Paraguayan' },
  { value: 'Peruvian', label: 'Peruvian' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Puerto Rican', label: 'Puerto Rican' },
  { value: 'Salvadoran', label: 'Salvadoran' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Uruguayan', label: 'Uruguayan' },
  { value: 'Venezuelan', label: 'Venezuelan' },
];

export enum FormSchemaKeys {
  GENDER = 'gender',
  HOMETOWN = 'hometown',
  GRAMMAR_SCHOOL = 'grammar_school',
  HIGH_SCHOOL = 'high_school',
  UNIVERSITY = 'university',
  UNDERGRAD_DEGREE = 'undergrad_degree',
  GRAD_DEGREE = 'grad_degree',
  INDUSTRY = 'industry',
  HERITAGE = 'heritage',
  SCHOLAR_YEAR = 'scholar_year',
}

export const formSchema: ISectionItem[] = [
  {
    label: 'Gender',
    name: FormSchemaKeys.GENDER,
    options: Sex,
  },
  {
    label: 'Hometown',
    name: FormSchemaKeys.HOMETOWN,
    options: Hometown,
  },
  {
    label: 'Grammar School',
    name: FormSchemaKeys.GRAMMAR_SCHOOL,
  },
  {
    label: 'High School',
    name: FormSchemaKeys.HIGH_SCHOOL,
    options: HighSchool,
  },
  {
    label: 'University',
    name: FormSchemaKeys.UNIVERSITY,
    options: University,
  },
  {
    label: 'Undergraduate Degree',
    name: FormSchemaKeys.UNDERGRAD_DEGREE,
    options: UndergraduateLevel,
  },
  {
    label: 'Graduate Degree',
    name: FormSchemaKeys.GRAD_DEGREE,
    options: GraduateLevel,
  },
  {
    label: 'Industry',
    name: FormSchemaKeys.INDUSTRY,
    options: Industry,
  },
  {
    label: 'Heritage',
    name: FormSchemaKeys.HERITAGE,
    options: Heritage,
  },
  {
    label: `Year Selected as an ${programShortName} Scholar`,
    name: FormSchemaKeys.SCHOLAR_YEAR,
    options: ScholarYearCounter(1975, 2023),
  },
];
